import { defineStore } from 'pinia'

import { accountSetupRepository } from '@/repositories'
import { useAccountOnboarding } from '@/composables'
import type { AccountSetupWizzardStepWithoutComponent } from '@/types'

export const useAccountSetupStore = defineStore('account-setup', () => {
  const {
    syncStripeData: syncStripeDataRepository,
    getStripeAccountLinkOAuth: getStripeAccountLinkOAuthRepository,
    getStripeSyncProgress: getStripeSyncProgressRepository,
  } = accountSetupRepository()

  const tenantStore = useTenantStore()
  const supabaseSession = useSupabaseSession()
  const { accountOnboardingSteps, getComponentForStep, isStepCompleted } = useAccountOnboarding()

  const { userActiveTenant } = storeToRefs(tenantStore)

  const _currentStep = ref<AccountSetupWizzardStepWithoutComponent | null>(null)
  const currentStep = computed(
    () => _currentStep.value || accountOnboardingSteps.find(
      ({ id }) => id === userActiveTenant.value?.onboarding.step,
    ) as AccountSetupWizzardStepWithoutComponent,
  )
  const previousStep = computed(
    () => accountOnboardingSteps.find(
      ({ step }) => step === currentStep.value.step - 1,
    ) || null as AccountSetupWizzardStepWithoutComponent | null,
  )
  const nextStep = computed(
    () => accountOnboardingSteps.find(
      ({ step }) => step === currentStep.value.step + 1,
    ) || null as AccountSetupWizzardStepWithoutComponent | null,
  )
  const steps = computed<AccountSetupWizzardStepWithoutComponent[]>(
    () => accountOnboardingSteps
      .map((step) => {
        return {
          ...step,
          completed: isStepCompleted(currentStep.value, step.id),
        }
      }),
  )

  async function getStripeAccountLinkOAuth() {
    if (userActiveTenant.value?.id) {
      const { data, error } = (await getStripeAccountLinkOAuthRepository()).value

      if (error) {
        // eslint-disable-next-line  no-console
        console.log(`ERROR: Failed to link Stripe account: ${error?.message}`)
      }
      if (data)
        return window.location.assign(data.url)
    }
    else {
      // eslint-disable-next-line  no-console
      console.log('ERROR: Tenant does not exist for the user')
    }
  }

  async function syncStripeData() {
    if (supabaseSession && userActiveTenant.value?.id) {
      const { data, error } = (await syncStripeDataRepository()).value

      if (error) {
        // eslint-disable-next-line  no-console
        console.log(`ERROR: Failed to sync Stripe account: ${error?.message}`)
      }
      if (data.status) {
        userActiveTenant.value = {
          ...userActiveTenant.value,
          stripe_account_data_sync_progress: data.status,
        }
      }
    }
    else {
      // eslint-disable-next-line  no-console
      console.log('ERROR: Tenant does not exist for the user')
    }
  }

  async function getStripeSyncProgress() {
    if (supabaseSession && userActiveTenant.value?.id) {
      const { data, error } = (await getStripeSyncProgressRepository()).value

      if (error) {
        // eslint-disable-next-line  no-console
        console.log(`ERROR: Failed to get Stripe sync progress: ${error?.message}`)
      }
      if (data.status) {
        userActiveTenant.value = {
          ...userActiveTenant.value,
          stripe_account_data_sync_progress: data.status,
        }
      }
    }
    else {
      // eslint-disable-next-line  no-console
      console.log('ERROR: Tenant does not exist for the user')
    }
  }

  function navigateToStep(step: AccountSetupWizzardStepWithoutComponent) {
    _currentStep.value = step
  }

  return {
    currentStep: readonly(currentStep),
    previousStep: readonly(previousStep),
    nextStep: readonly(nextStep),
    steps: readonly(steps),
    getComponentForStep,
    getStripeAccountLinkOAuth,
    syncStripeData,
    getStripeSyncProgress,
    navigateToStep,
  }
})
