import { TenantStripeDataSyncStatus } from '@refloat/supabase'
import type { DBDriverError } from '@refloat/supabase'

import type {
  AccountSyncStripeDataProgress,
  MappedSyncStripeAccountResponse,
} from '@/types'

export function accountSetupRepository() {
  const { $api } = useNuxtApp()

  async function getStripeAccountLinkOAuth(): Promise<Ref<MappedSyncStripeAccountResponse>> {
    const payload = ref<MappedSyncStripeAccountResponse | null>(null)

    try {
      const response = await $api(`/stripe/link-oauth`, {
        method: 'GET',
      }) as MappedSyncStripeAccountResponse

      payload.value = response
    }
    catch (error) {
      payload.value = {
        data: null,
        error: error as DBDriverError,
      }
    }

    return payload as Ref<MappedSyncStripeAccountResponse>
  }

  async function syncStripeData(): Promise<Ref<AccountSyncStripeDataProgress>> {
    const payload = ref<AccountSyncStripeDataProgress | null>(null)

    try {
      const response = await $api(`/stripe/sync-data`, {
        method: 'GET',
      }) as AccountSyncStripeDataProgress

      payload.value = response
    }
    catch (error) {
      payload.value = {
        data: {
          status: TenantStripeDataSyncStatus.FAILED,
        },
        error: error as DBDriverError,
      }
    }

    return payload as Ref<AccountSyncStripeDataProgress>
  }

  async function getStripeSyncProgress(): Promise<Ref<AccountSyncStripeDataProgress>> {
    const payload = ref<AccountSyncStripeDataProgress | null>(null)

    try {
      const response = await $api(`/stripe/sync-progress`, {
        method: 'GET',
      }) as AccountSyncStripeDataProgress

      payload.value = response
    }
    catch (error) {
      payload.value = {
        data: {
          status: TenantStripeDataSyncStatus.FAILED,
        },
        error: error as DBDriverError,
      }
    }

    return payload as Ref<AccountSyncStripeDataProgress>
  }

  return {
    getStripeAccountLinkOAuth,
    syncStripeData,
    getStripeSyncProgress,
  }
}
