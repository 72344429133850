import { isEmail, isEmpty, isLength } from 'validator'

export function useFormValidations() {
  function isValidInput(value: string, min: number = 3, max: number = 255) {
    if (!value?.trim())
      return false

    return !isEmpty(value, { ignore_whitespace: true }) && isLength(value, { min, max })
  }

  function isValidEmail(value: string, min: number = 3, max: number = 255) {
    if (!isValidInput(value, min, max))
      return false

    return isEmail(value, { require_tld: true })
  }

  function isValidName(value: string, min: number = 2, max: number = 255) {
    return isValidInput(value, min, max)
  }

  function isInList(list: string[], value: string) {
    return list.includes(value)
  }

  return {
    isValidInput,
    isValidName,
    isValidEmail,
    isInList,
  }
}
