<script lang="ts" setup>
import { TenantStripeDataSyncStatus } from '@refloat/supabase'

const tenantStore = useTenantStore()
const { getTenant } = tenantStore
const { syncStripeData } = useAccountSetupStore()

const { userActiveTenant } = storeToRefs(tenantStore)
let timer: ReturnType<typeof setInterval> | null = null

onMounted(async () => {
  //  Get initial stripe data sync progress
  await getTenant()

  //  If the stripe data sync is in progress, start polling
  //  Polling for stripe data sync progress
  //  TODO: Refactor this to use a proper polling mechanism (SSER, Websockets, etc.)
  if (userActiveTenant.value?.stripe_account_data_sync_progress === TenantStripeDataSyncStatus.SYNCING)
    timer = setDataPolling()
})

onUnmounted(() => {
  if (timer)
    clearInterval(timer)
})

watch(userActiveTenant, async (newValue) => {
  if (newValue?.stripe_account_data_sync_progress === TenantStripeDataSyncStatus.SYNCED) {
    if (timer)
      clearInterval(timer)
  }
  if (newValue?.stripe_account_data_sync_progress === TenantStripeDataSyncStatus.SYNCING) {
    if (!timer)
      timer = setDataPolling()
  }
})

function setDataPolling() {
  return setInterval(async () => {
    await getTenant()
  }, 5_000)
}
</script>

<template>
  <section class="flex flex-col space-y-8">
    <!-- Sync Stripe data -->
    <template v-if="userActiveTenant?.stripe_account_data_sync_progress === TenantStripeDataSyncStatus.NOT_SYNCED">
      <div>
        <h2 class="text-lg text-rfblue-900 font-semibold leading-6 tracking-wide">
          Sync Stripe account data
        </h2>
        <p class="mt-6 text-base text-rfblue-900 font-normal leading-8 tracking-wide">
          In order to get the best possible offers for your customers, we need to get the insights about your customers from within your <strong class="tracking-wide">Stripe</strong> account, that will be the starting point for our customer segmentation.
          <br>
          Would you like the start the asynchronous process of customer segmentation now?
          <br>
          <em class="text-sm leading-5">* (This process may take up to a few hours to complete.)</em>
        </p>
      </div>
      <button class="relative block flow-root max-w-xl flex items-center rounded-xl p-2 text-left -m-2 space-x-4 hover:bg-rfblue-200/20 focus-within:ring-1 focus-within:ring-rfblue-500" @click.prevent="syncStripeData">
        <span class="h-24 w-24 flex flex-shrink-0 items-center justify-center rounded-lg bg-rfblue-500">
          <span class="i-heroicons-outline:user-group h-16 w-16 text-white" />
        </span>
        <span class="flex flex-col text-base text-rfblue-900 font-medium">
          <span class="font-sp tracking-wide">
            Start Stripe account data sync
            <span aria-hidden="true"> &rarr;</span>
          </span>
          <span class="mt-1 text-base text-sm text-rfblue-900 font-400 font-normal tracking-wide font-inter">and get notified once the process is complete.</span>
        </span>
      </button>
    </template>

    <!-- Stripe data syncing -->
    <template v-if="userActiveTenant?.stripe_account_data_sync_progress === TenantStripeDataSyncStatus.SYNCING">
      <h2 class="text-lg text-rfblue-900 font-semibold leading-6 tracking-wide">
        Stripe account data sync in progress. . .
      </h2>
      <p class="mt-6 text-base text-rfblue-900 font-normal leading-8 tracking-wide">
        We're syncing and segmenting the customer's data from the connected <strong class="tracking-wide">Stripe</strong> account, in order to get the better insights about customers behaviour and determine the best possible offers for your customers.
        <br>
        <em class="text-sm leading-5">* (This process may take up to a few hours to complete, so we ask for your patience)</em>
      </p>
      <span class="i-refloat:segmentation-loader mx-auto h-42 w-42 opacity-90 space-y-24" aria-hidden="true" />
    </template>

    <!-- Stripe data sync failed -->
    <template v-if="userActiveTenant?.stripe_account_data_sync_progress === TenantStripeDataSyncStatus.FAILED">
      <h2 class="text-lg text-rfblue-900 font-semibold leading-6 tracking-wide">
        Stripe account data sync failed
      </h2>
      <p class="mt-6 text-base text-rfblue-900 font-normal leading-8 tracking-wide">
        We were unable to perform the synchronization processes.
        <br>
        This means that we did not get insights about your customers and our data segmentation processes did not go well.
        <br>
        This is unfortunate!
      </p>
      <template v-if="!userActiveTenant.stripe_account_onboarded">
        <p class="mt-6 text-base text-rfblue-900 font-normal leading-8 tracking-wide">
          You will need to verify your Stripe account first in order to sync your Stripe account data to <strong class="text-rfblue-600 tracking-wide">Refloat</strong>!
          <br>
          Please, go to <a class="text-rfblue-600 hover:underline" href="https://dashboard.stripe.com/settings/update" target="_blank" rel="noopener noreferrer">Stripe account dashboard</a> and follow the instructions on how to complete the verification process for your account (or copy the following link manually into your browser (https://dashboard.stripe.com/settings/update), then try again.
        </p>
      </template>
      <p class="mt-6 text-base text-rfblue-900 font-normal leading-8 tracking-wide">
        We can try to synchronize the data once again.
        <br>
        Would you like to restart the processes?
        <br>
      </p>
      <button class="relative block flow-root max-w-xl flex items-center rounded-xl p-2 text-left -m-2 space-x-4 hover:bg-rfblue-200/20 focus-within:ring-1 focus-within:ring-rfblue-500">
        <span class="h-24 w-24 flex flex-shrink-0 items-center justify-center rounded-lg bg-rfblue-500">
          <span class="i-heroicons-outline:arrow-path h-16 w-16 text-white" />
        </span>
        <span class="flex flex-col text-base text-rfblue-900 font-medium">
          <span class="font-sp tracking-wide">
            Retry the Stripe data synchronization
            <span aria-hidden="true"> &rarr;</span>
          </span>
          <span class="mt-1 text-base text-sm text-rfblue-900 font-400 font-normal tracking-wide font-inter">and get notified once the process is complete.</span>
        </span>
      </button>
      <em class="text-base text-sm text-rfblue-900 font-normal leading-5 tracking-wide">*
        Please reach out to us at <a href="mailto:support@refloat.com" class="text-indigo-600">mailto:support@refloat.com</a> for further assistance or any questions if you're unable to restart the processes or the data synchronization failed again.
        <br>
        We are here to help!
      </em>
    </template>
  </section>
</template>
