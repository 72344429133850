<script lang="ts" setup>
import { ClipboardDocumentCheckIcon, ClipboardDocumentIcon } from '@heroicons/vue/24/outline'
import { useClipboard } from '@vueuse/core'

const props = defineProps<{
  contentToCopy: string
}>()

const source = ref<string>(props.contentToCopy)
const copyButton = ref()
const { copy, copied } = useClipboard({
  source,
  copiedDuring: 2000,
})
const isCopyButtonHovered = useElementHover(copyButton)
</script>

<template>
  <div class="rounded-lg">
    <button ref="copyButton" class="peer inline-flex items-center justify-center p-2 text-gray-400 leading-2" @click="copy()">
      <ClipboardDocumentCheckIcon v-if="copied" class="h-6 w-6" aria-hidden="true" />
      <ClipboardDocumentIcon v-else class="h-6 w-6" />
    </button>
    <div v-if="isCopyButtonHovered" class="tooltip absolute z-10 mt-10 inline-block rounded-lg px-3 py-2 text-xs text-gray-400 font-medium opacity-0 shadow-md transition-opacity duration-300 -ml-24 peer-hover:opacity-100">
      <span v-if="copied" id="success-tooltip-message">Copied!</span>
      <span v-else id="default-tooltip-message">Copy to clipboard</span>
      <div class="tooltip-arrow" />
    </div>
  </div>
</template>
