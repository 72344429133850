<template>
  <div class="mx-auto divide-y divide-gray-900/10">
    <h3 class="text-2xl text-gray-900 font-bold leading-10 tracking-tight">
      <slot name="accordionTitle" />
    </h3>
    <dl class="mt-10 space-y-6 divide-y divide-gray-900/10">
      <slot />
    </dl>
  </div>
</template>
