<script setup lang="ts">
import { TenantStripeDataSyncStatus } from '@refloat/supabase'

import AccountSetupSyncStripeData from './partials/AccountSetupSyncStripeData.vue'
import AccountSetupInstructions from './partials/AccountSetupInstructions.vue'

const tenantStore = useTenantStore()
const { userActiveTenant } = storeToRefs(tenantStore)

const { getTenant } = tenantStore

const currentStep = computed(() => {
  if (
    userActiveTenant.value?.stripe_account_data_sync_progress === TenantStripeDataSyncStatus.NOT_SYNCED
  )
    return AccountSetupSyncStripeData
  else
    return AccountSetupInstructions
})

onMounted(async () => {
  //  Get initial stripe data sync progress
  await getTenant()
})
</script>

<template>
  <component :is="currentStep" />
</template>
