import type { AccountSetupWizzardStep, AccountSetupWizzardStepWithoutComponent } from '@/types'
import AccountSetupTenantInfo from '@/components/OnBoarding/AccountSetupTenantInfo.vue'
import AccountSetupConnectStripe from '@/components/OnBoarding/AccountSetupConnectStripe.vue'
import AccountSetupFinal from '@/components/OnBoarding/AccountSetupFinal.vue'

export function useAccountOnboarding() {
  const ONBOARDING_STEPS_UI: AccountSetupWizzardStep[] = [
    {
      id: 'company_profile_setup',
      step: 1,
      title: 'Company Profile Setup',
      completed: false,
      component: AccountSetupTenantInfo,
    },
    {
      id: 'connect_stripe_account',
      step: 2,
      title: 'Connect Stripe Account',
      completed: false,
      component: AccountSetupConnectStripe,
    },
    {
      id: 'integration_instructions',
      step: 3,
      title: 'Integration Instructions',
      completed: false,
      component: AccountSetupFinal,
    },
  ]

  const accountOnboardingSteps = ONBOARDING_STEPS_UI
    .map((step) => {
      return (Object.keys(step) as Array<keyof AccountSetupWizzardStep>)
        .filter(key => key !== 'component')
        .reduce((stepWithoutComponent, key) => {
          return {
            ...stepWithoutComponent,
            [key]: step[key],
          }
        }, {} as AccountSetupWizzardStepWithoutComponent)
    })

  function getComponentForStep(step: AccountSetupWizzardStepWithoutComponent) {
    return ONBOARDING_STEPS_UI.find(stepWithComponent => stepWithComponent?.id === step?.id)?.component
  }

  function isStepCompleted(currentStep: AccountSetupWizzardStepWithoutComponent, stepId: AccountSetupWizzardStep['id']) {
    const foundStep: AccountSetupWizzardStep = ONBOARDING_STEPS_UI.find(step => step?.id === stepId)!
    return currentStep ? currentStep.step > foundStep?.step : false
  }

  return {
    accountOnboardingSteps: readonly(accountOnboardingSteps),
    getComponentForStep,
    isStepCompleted,
  }
}
