import { defineStore } from 'pinia'

export const useUiStore = defineStore('ui', () => {
  const sidebarShown = ref(true)

  function showSidebar() {
    sidebarShown.value = true
  }

  function hideSidebar() {
    sidebarShown.value = false
  }

  return {
    sidebarShown,
    showSidebar,
    hideSidebar,
  }
})
