<script lang="ts" setup>
import { debounce } from 'lodash-es'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'

import type { TenantHeardAboutUs, UpdateTenant } from '@refloat/supabase'

import { useTenantStore } from '@/stores'
import { useFormValidations } from '@/composables'
import { ONBOARDING_ROLE } from '@/constants'

const props = defineProps<{ tenant: Partial<UpdateTenant & TenantHeardAboutUs> | null }>()

const { gtag } = useGtag()
const { updateTenantInfo } = useTenantStore()
const { isInList, isValidName, isValidEmail, isValidInput } = useFormValidations()

const personName = ref<string | null>(props.tenant?.person_name || null)
const companyName = ref<string | null>(props.tenant?.company_name || null)
const companyEmail = ref<string | null>(props.tenant?.company_contact_email || null)
const personRole = ref<keyof typeof ONBOARDING_ROLE | null>(
  props.tenant?.person_role as keyof typeof ONBOARDING_ROLE || null,
)
const aboutUsFeedback = ref<string | null>(null)
const isTenantInfoValid = ref<boolean>(false)
const personSelectedRoleText = computed(
  () => personRole.value ? ONBOARDING_ROLE[personRole.value] : 'Select Your Role',
)

const isTenantInfoValidDebounced = debounce(() => (
  isTenantInfoValid.value = isValidName(personName.value!)
  && isValidName(companyName.value!)
  && isValidEmail(companyEmail.value!)
  && (
    personRole.value
      ? isInList(Object.keys(ONBOARDING_ROLE), personRole.value!)
      : true
  )
  && (
    aboutUsFeedback.value
      ? isValidInput(aboutUsFeedback.value)
      : true
  )
), 500)

function updateFormState() {
  isTenantInfoValid.value = false
  isTenantInfoValidDebounced()
}

async function proceed(evt: Event) {
  evt.preventDefault()
  evt.stopPropagation()

  if (isTenantInfoValid.value) {
    await updateTenantInfo({
      person_name: personName.value!,
      company_name: companyName.value!,
      company_contact_email: companyEmail.value!,
      person_role: personRole.value!,
      heard_about_us_from: aboutUsFeedback.value!,
    })
  }
}

onMounted(() => {
  isTenantInfoValidDebounced()
  gtag('event', 'conversion', {
    send_to: 'AW-16679367489/he9SCOmr0c0ZEMHmq5E-',
  })
})
</script>

<template>
  <form
    class="w-full space-y-8"
    :disabled="!isTenantInfoValid"
    @input="updateFormState"
    @submit.prevent="proceed"
  >
    <div class="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
      <div class="space-y-2">
        <h2 class="text-base text-gray-900 font-semibold leading-7">
          Personal Information
        </h2>
      </div>

      <div class="grid grid-cols-1 max-w-2xl gap-x-6 gap-y-8 md:col-span-2 sm:grid-cols-6">
        <div class="sm:col-span-3">
          <label for="full-name" class="required block text-sm text-gray-900 font-medium leading-6">Your name</label>
          <div class="mt-2">
            <input
              id="full-name"
              v-model="personName"
              name="full-name"
              type="text"
              autocomplete="name"
              class="block w-full border-0 rounded-md px-3 py-1.5 text-rfblue-900 shadow-sm ring-1 ring-rfblue-900/50 ring-inset sm:text-sm placeholder:text-rfblue-900/50 sm:leading-6 focus:ring-1 focus:ring-rfblue-600 focus:ring-inset"
              placeholder="Full name"
            >
          </div>
        </div>

        <div class="sm:col-span-4">
          <Listbox v-model="personRole" as="div">
            <ListboxLabel class="optional block text-sm text-gray-900 font-medium leading-6">
              What's your role?
            </ListboxLabel>
            <div class="relative mt-2">
              <ListboxButton class="relative w-full cursor-default cursor-pointer rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-rfblue-900 shadow-sm ring-1 ring-rfblue-900/50 ring-inset sm:text-sm sm:leading-6 focus:outline-none focus:ring-1 focus:ring-rfblue-600 focus:ring-inset">
                <span class="block truncate text-rfblue-900">{{ personSelectedRoleText }}</span>
                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </ListboxButton>

              <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                <ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto border-0 rounded-md bg-white py-1 text-base text-rfblue-900 shadow-lg ring-1 ring-rfblue-900/50 ring-inset sm:text-sm focus:outline-none focus:ring-1 focus:ring-rfblue-600 focus:ring-inset">
                  <ListboxOption v-for="(description, role) in ONBOARDING_ROLE" :key="role" v-slot="{ active, selected }" :value="role" as="template">
                    <li class="relative cursor-pointer select-none py-2 pl-8 pr-4 text-rfblue-900" :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                      <span class="block truncate" :class="[selected ? 'font-semibold' : 'font-normal']">{{ description }}</span>

                      <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-1.5" :class="[active ? 'text-white' : 'text-indigo-600']">
                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                      </span>
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </transition>
            </div>
          </Listbox>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
      <div class="space-y-2">
        <h2 class="text-base text-gray-900 font-semibold leading-7">
          Company Information
        </h2>
        <p class="mt-1 text-sm text-gray-600 leading-6">
          This information will be shared with your customers. The email address is used to forward support request that are captured during the cancellation flows.
        </p>
      </div>

      <div class="grid grid-cols-1 max-w-2xl gap-x-6 gap-y-8 md:col-span-2 sm:grid-cols-6">
        <div class="sm:col-span-3">
          <label for="company-name" class="required block text-sm text-gray-900 font-medium leading-6">Company name</label>
          <div class="mt-2">
            <input
              id="company-name"
              v-model="companyName"
              name="company-name"
              type="text"
              autocomplete="name"
              class="block w-full border-0 rounded-md px-3 py-1.5 text-rfblue-900 shadow-sm ring-1 ring-rfblue-900/50 ring-inset sm:text-sm placeholder:text-rfblue-900/50 sm:leading-6 focus:ring-1 focus:ring-rfblue-600 focus:ring-inset"
              placeholder="Company's Legal Name"
            >
          </div>
        </div>

        <div class="sm:col-span-4">
          <label for="company-email" class="required block text-sm text-gray-900 font-medium leading-6">Support email address</label>
          <div class="mt-2">
            <input
              id="company-email"
              v-model="companyEmail"
              name="company-email"
              type="email"
              autocomplete="email"
              class="block w-full border-0 rounded-md px-3 py-1.5 text-rfblue-900 shadow-sm ring-1 ring-rfblue-900/50 ring-inset sm:text-sm placeholder:text-rfblue-900/50 sm:leading-6 focus:ring-1 focus:ring-rfblue-600 focus:ring-inset"
              placeholder="Where should customer support messages be sent?"
            >
          </div>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
      <div class="space-y-2">
        <h2 class="text-base text-gray-900 font-semibold leading-7">
          Additional information
        </h2>
        <p class="mt-1 text-sm text-gray-600 leading-6">
          Additional information that helps us to tailor our services to your needs.
        </p>
      </div>

      <div class="grid grid-cols-1 max-w-2xl gap-x-6 gap-y-8 md:col-span-2 sm:grid-cols-6">
        <div class="sm:col-span-4">
          <label for="feedback" class="optional block text-sm text-gray-900 font-medium leading-6">Where did you hear about us?</label>
          <div class="mt-2">
            <input
              id="feedback"
              v-model="aboutUsFeedback"
              name="feedback"
              type="text"
              autocomplete="email"
              class="block w-full border-0 rounded-md px-3 py-1.5 text-rfblue-900 shadow-sm ring-1 ring-rfblue-900/50 ring-inset sm:text-sm placeholder:text-rfblue-900/50 sm:leading-6 focus:ring-1 focus:ring-rfblue-600 focus:ring-inset"
              placeholder="Website, forum, search, etc."
            >
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6 flex items-center justify-end gap-x-6">
      <button type="submit" class="rounded-md bg-rfblue-600 px-3 py-2 text-sm text-white font-semibold shadow-sm disabled:cursor-not-allowed disabled:bg-gray-400/80 hover:bg-rfblue-500 focus-visible:outline-2 focus-visible:outline-rfblue-600 focus-visible:outline-offset-2 focus-visible:outline" :disabled="!isTenantInfoValid">
        Next
      </button>
    </div>
  </form>
</template>

<style lang="scss" scoped>
.optional::after {
  content: ' (optional)';
  font-size: 0.75rem;
  font-style: italic;
}

.required::after {
  content: ' *';
}

input:placeholder-shown {
  font-style: italic;
}
</style>
