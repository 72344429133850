<script setup lang="ts">
import { useAccountSetupStore } from '@/stores'

const accountSetupStore = useAccountSetupStore()
async function connectStripe() {
  await accountSetupStore.getStripeAccountLinkOAuth()
};
</script>

<template>
  <section class="flex flex-col space-y-8">
    <div>
      <h2 class="text-lg text-rfblue-900 font-semibold leading-6 tracking-wide">
        Stripe integration
      </h2>
      <p class="mt-6 text-base text-rfblue-900 font-normal leading-6 tracking-wide">
        Refloat needs to connect to your <strong class="tracking-wider">Stripe</strong> account to analyze and segment your customers, and to be able to add offers to their subscriptions during the cancellation flows.
        <br>
        Get started by clicking on the button bellow.
      </p>
    </div>
    <button class="relative block flow-root max-w-xl flex items-center rounded-xl p-2 text-left -m-2 space-x-4 hover:bg-rfblue-200/20 focus-within:ring-1 focus-within:ring-rfblue-500" @click.prevent="connectStripe">
      <span class="h-24 w-24 flex flex-shrink-0 items-center justify-center rounded-lg bg-rfblue-500">
        <span class="i-fontawesome-brands:stripe h-18 w-18 text-white" />
      </span>
      <span class="flex flex-col text-base text-rfblue-900 font-medium">
        <span class="font-sp tracking-wide">
          Connect Stripe account
          <span aria-hidden="true"> &rarr;</span>
        </span>
        <span class="mt-1 text-base text-sm text-rfblue-900 font-400 font-normal tracking-wide font-inter">by following the connect wizard.</span>
      </span>
    </button>
  </section>
</template>
